import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {LoginRequest} from '../models/request/login.request';
import {BaseResponse} from '../models/respose/BaseResponse';
import {User} from '../models/User';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {

  @Output() userlogin = new EventEmitter<User>();

  isUserLogged(): boolean {
    return !!localStorage.getItem('token');
  }

  login(username: string, password: string): Observable<BaseResponse<User>> {
    return this.post('login', new LoginRequest(username, password));
  }

  logout(): void {
    this.get('logout').subscribe(
      result => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    );
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user') as string);
  }

  public canManageConfiguration(): boolean {
    // @ts-ignore
    return this.getUser().roles?.filter(role => (role.code === 'MANTAINER')).length > 0;
  }

  public canManageCompanies(): boolean {
    // @ts-ignore
    return this.getUser().roles?.filter(role => (role.code === 'MANAGE_COMPANIES')).length > 0;
  }

  public canManageContacts(): boolean {
    // @ts-ignore
    return this.getUser().roles?.filter(role => (role.code === 'MANAGE_CONTACTS')).length > 0;
  }

  public canManagePractices(): boolean {
    // @ts-ignore
    return this.getUser().roles?.filter(role => (role.code === 'MANAGE_PRACTICES')).length > 0;
  }

  public canManageProspects(): boolean {
    // @ts-ignore
    return this.hasRole('MANAGE_PROSPECTS') || this.hasRole('SYSTEM');
  }

  public hasRole(roleString: string): boolean {
    // @ts-ignore
    return this.getUser().roles?.filter(role => (role.code === roleString)).length > 0;
  }

}
