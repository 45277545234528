<div *ngIf="element?.invalid && (element?.dirty || element?.touched)" class="text-danger small">
  <div *ngIf="element?.errors?.required">
    Campo obbligatorio
  </div>
  <div *ngIf="element?.errors?.minlength">
    La lunghezza minima di questo campo è {{ element?.errors?.minlength.requiredLength }} caratteri.
  </div>
  <div *ngIf="element?.errors?.maxlength">
    La lunghezza minima di questo campo è {{ element?.errors?.maxlength.requiredLength }} caratteri.
  </div>
  <div *ngIf="element?.errors?.email">
    Formato email non corretto.
  </div>
  <div *ngIf="element?.errors?.mustMatch">
    I due campi devono coincidere
  </div>
  <div *ngIf="element?.errors?.onlyNumbers">
    Questo campo non può contenere lettere
  </div>
  <div *ngIf="element?.errors?.rangeDate">
    Specificare una data nel range {{ element?.errors?.rangeDate.rangeMin | date:'dd/MM/yyyy' }}
    - {{ element?.errors?.rangeDate.rangeMax | date:'dd/MM/yyyy' }}
  </div>
  <div *ngIf="element?.errors?.minDate">
    Specificare una data dal {{ element?.errors?.minDate.min | date:'dd/MM/yyyy' }}
  </div>
  <div *ngIf="element?.errors?.emails">
    Le email devono essere in un formato valido e divise da ';' senza spazi
  </div>
  <div *ngIf="element?.errors?.ivaExists">
    Partita iva già
    inserita {{ (element?.errors?.ivaExists?.contact?.user) ? ' e gestita da: ' + element?.errors?.ivaExists?.contact?.user?.first_name + ' ' + element?.errors?.ivaExists?.contact?.user?.last_name : ' e non ancora assegnata' }}
  </div>
  <div *ngIf="element?.errors?.attachmentsRequired">
    Inserire almeno un documento
  </div>


</div>
