// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {menus} from '../app/shared/vars/menus';

export const environment = {
  project: 'hrss',
  production: false,
  // api: 'http://localhost:8031/api/admin/',
  api: 'https://api.hrserviziosalute.soluzionitop.com/api/admin/',
  api_otp: 'http://localhost:8031/api/otp/',
  offers_link: 'http://localhost:4300/',
  max_file_size_kb: 51200,
  project_name: 'HRSS',
  assets_path: 'hrss',
  logo_bg_color: '#184689'
};

export const menu = menus.hrss;
/* For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
