<div [style.width.%]="100" class="card-body table-responsive p-0">
  <table class="table table-hover text-nowrap">
    <thead>
    <tr>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'company_name'"
                         [label]="'Nome azienda'"></app-sort-button>
      </th>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'created_at'"
                         [label]="'Data Inser.'"></app-sort-button>
      </th>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'iva'"
                         [label]="'Partita iva'"></app-sort-button>
      </th>
      <th>Città/Prov.</th>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'email'"
                         [label]="'Email'"></app-sort-button>
      </th>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'user_name'"
                         [label]="'Operatore'"></app-sort-button>
      </th>
      <th>
        <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'assigned_at'"
                         [label]="'Data Assegn.'"></app-sort-button>
      </th>
      <th *ngIf="project === 'clemps' || project === 'pmgroup' || project === 'maxcredit' || project === 'deluca'">
        Fonte
      </th>
      <th *ngIf="enableDeleteButton || enableEditButton"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let contact of paginatedResponse.data">
      <td (click)="navigateToPageWithId(contact.id !== undefined ? contact.id.toString() : '')"
          *ngIf="!disableDetails" style="max-width: 20em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        <span style="cursor: pointer; color: #007bff">{{ contact.company_name | titlecase }}</span>
      </td>
      <td *ngIf="disableDetails"
          style="max-width: 20em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.company_name | titlecase }}
      </td>
      <td
        style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.created_at | date: 'dd/MM/yyyy' }}
      </td>
      <td style="max-width: 10em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.iva }}
      </td>
      <td
        style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.city?.name }}
        /{{ contact.city?.province?.name }}
      </td>
      <td style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"><a
        href="mailto:{{contact.email}}">{{ contact.email }}</a></td>
      <td
        style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.user?.last_name | titlecase }} {{ contact.user?.first_name | titlecase }}
      </td>
      <td
        style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ contact.assigned_at | date: 'dd/MM/yyyy HH:mm' }}
      </td>
      <td
        *ngIf="project === 'clemps' || project === 'pmgroup' || project === 'maxcredit' || project === 'deluca'">{{ contact.source?.name }}
      </td>
      <td *ngIf="enableDeleteButton || enableEditButton" class="text-right"
          style="max-width: 10em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        <button (click)="edit(contact)" *ngIf="enableEditButton" class="btn btn-primary" data-placement="top" data-toggle="tooltip"
                title="Modifica" type="button"><i class="fas fa-edit"></i></button>
        <button (click)="openTrashModal(content,contact)" *ngIf="enableDeleteButton" class="btn btn-danger ms-2" data-placement="top"
                data-toggle="tooltip" title="Cancella" type="button"><i
          class="fas fa-trash-alt"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="enablePagination" class="card-footer">
  <app-pagination (elementsChange)="onElementsChange($event)" (pageChangeEvent)="updateList($event)"
                  [paginatedResponse]="paginatedResponse"></app-pagination>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Elimina</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Confermi l'eliminazione dell'azienda?
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('confirm')" class="btn btn-outline-dark" type="button">Conferma</button>
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" type="button">Annulla</button>
  </div>
</ng-template>
